<template>
  <!-- Version: 1.7 -->
  <h2>1. Descrizione del gioco</h2>
  <p>
    Il gioco Soccerbet di Highlight Games è una simulazione software esclusiva di momenti salienti di partite di calcio
    selezionati in maniera casuale per creare opportunità di gioco.
  </p>
  <h2>2. Descrizione e lunghezza del gioco</h2>
  <p>
    “Un singolo evento di un gioco di scommesse virtuali ha una durata compresa tra 3 e 5 minuti e comprende
    l&#39;annuncio della partita, l&#39;introduzione, il video della partita e i risultati. Il video della partita dura
    1 minuto”.
  </p>
  <p>
    La selezione degli eventi calcistici più importanti è statisticamente indipendente, casuale e imprevedibile ed è
    generata dal generatore di numeri casuali (RNG).
  </p>
  <h2>3. Mercati di scommesse</h2>
  <h3>3.1 Somma goal</h3>
  <p>
    Il significato della scommessa Somma esatta di goal è che in una singola partita deve essere segnato un determinato
    numero di goal. Quindi, questa variante di scommessa significa che si deve pronosticare un numero ben preciso di
    goal indipendentemente dal risultato finale di un&#39;intera partita.
  </p>
  <h3>3.2 Somma goal casa</h3>
  <p>Una scommessa sul numero esatto di goal che la squadra di casa segnerà nella partita.</p>
  <h3>3.3 Somma goal ospite</h3>
  <p>Una scommessa sul numero esatto di goal che la squadra ospite segnerà nella partita.</p>
  <h3>3.4 Entrambe le squadre segnano (BTTS)</h3>
  <p>
    Per vincere una scommessa BTTS (Both Teams To Score, Entrambe le squadre segnano), entrambe le squadre dovranno
    segnare almeno un goal, rendendo qualsiasi risultato da 1-1 in su una scommessa vincente. La scommessa
    &quot;Entrambe le squadre segnano&quot; dà agli scommettitori la possibilità di approfittare di tali eventi, al fine
    di ottenere un profitto quando solo una delle due squadre è riuscita a segnare.
  </p>
  <h3>3.5 Risultato esatto</h3>
  <p>
    Scommettere sul Risultato esatto significa scommettere su quello che si pensa sarà il risultato finale di una
    partita di calcio, con un massimo di 6 goal; in una partita i risultati possibili sono 28.
  </p>
  <h3>3.6 Doppia chance</h3>
  <p>
    Una scommessa doppia chance consente di coprire due dei tre possibili risultati di una partita di calcio con una
    scommessa. Squadra di casa e pareggio: la scommessa è vincente se la squadra di casa vince o pareggia. Squadra
    ospite e pareggio: la scommessa è vincente se la squadra ospite vince o pareggia.
  </p>
  <h3>3.7 Esito finale (1X2)</h3>
  <p>
    Una scommessa sulla vittoria della squadra di casa (1), sulla vittoria della squadra ospite (2) o sulla partita che
    termina con un pareggio (X).
  </p>
  <h3>3.8 Esito finale e Entrambe le squadre segnano (1X2 + BTTS)</h3>
  <p>
    Una scommessa sull&#39;esito della partita (1 è la vittoria della squadra di casa, X è il pareggio, 2 è la vittoria
    della squadra ospite) e sul fatto che entrambe le squadre segneranno o meno.
  </p>
  <ul>
    <li>1 + Goal: la squadra di casa vince ed entrambe le squadre segnano almeno un goal [p. es., 3-1].</li>
    <li>1 + No Goal: la squadra di casa vince ed è l&#39;unica squadra a segnare un goal [p. es., 1-0].</li>
    <li>X + Goal: scommessa su un pareggio e sul fatto che entrambe le squadre segneranno [p. es., 1-1, 2-2].</li>
    <li>X + No Goal: scommessa su un pareggio e sul fatto che nessuna squadra segnerà goal. [Solo 0-0].</li>
    <li>2 + Goal: la squadra ospite vince ed entrambe le squadre segnano almeno un goal [p. es., 1-2, 1-3].</li>
    <li>2 + No Goal: la squadra ospite vince ed è l&#39;unica squadra a segnare goal [p. es., 0-1, 0-2].</li>
  </ul>
  <h3>3.9 Esito finale e Over/Under 1,5 goal</h3>
  <p>
    Una scommessa sull’esito della partita 1X2 e sul numero totale di goal segnati inferiore o superiore a 1,5 goal.
  </p>
  <h3>3.10 Esito finale e Over/Under 2,5 goal</h3>
  <p>
    Una scommessa sull’esito della partita 1X2 e sul numero totale di goal segnati inferiore o superiore a 2,5 goal.
  </p>
  <h3>3.11 Multigol (da 1 a 2, da 1 a 3, da 1 a 4, da 2 a 3, da 2 a 4)</h3>
  <p>
    Una scommessa sul fatto che la partita avrà da 1 a 2, da 1 a 3, da 1 a 4, da 2 a 3 o da 2 a 4 goal totali come
    risultato finale. Questa è una scommessa SÌ/NO.
  </p>
  <h3>3.12 Over/Under 1,5 casa</h3>
  <p>Una scommessa sul fatto che la squadra di casa segnerà più o meno di 1,5 goal nella partita.</p>
  <h3>3.13 Over/Under 1,5 ospite</h3>
  <p>Una scommessa sul fatto che la squadra ospite segnerà più o meno di 1,5 goal nella partita.</p>
  <h3>3.14 Over/Under 1,5, 2,5, 3,5, 4,5 goal totali</h3>
  <p>
    Una scommessa sul numero totale di goal segnati durante la partita da entrambe le squadre, inferiore o superiore a
    1,5, 2,5, 3,5 o 4,5.
  </p>
</template>
